import {
    ORDERS,
    REGISTER_API,
    TRACKINFO,
    FACEBOOKLOGIN,
    LOGIN_MONITOR_BEFORE_API,
    LOGIN_MONITOR_AFTER_API,
    LOGIN_API,
    DECODE,
    SENDFORGETPASSWORD,
    ORDERCODE,
    USER_PHOTO_SET_EYE_POSITION,
    USER_PHOTO,
    S3_URL,
    PHOTO,
    POST_REVIEW,
    ORDERS_REFUND,
    SERVICESHOW,
    CHANGE,
    ORDERS_SERVICE,
    ORDERS_SERVICE_DELETE,
    PROMOTION_COUPON,
    INDEX_PRODUCTS,
    SUBSCRIPTION,
    UNSUBSCRIBE,
    AD,
    COUNTRIES,
} from "@/config/api";
import {
    getCookieItem,
    isServer,
    removeCookieItem,
    setCookieItem,
} from "@/lib/commonService";

import { INVITECODE_COOKIE_NAME, JWT_COOKIE_NAME } from "@/config/constConf";

import HandlePhpFetch from "@/src/utils/fetch/PhpFetch";
import HandleJavaCommonFetch from "@/src/utils/fetch/JavaCommonFetch";
import HandleJavaSearchFetch from "@/src/utils/fetch/JavaSearchFetch";
import HandleJavaOpencvFetch from "@/src/utils/fetch/JavaOpencvFetch";
import { performanceRecordWrapper } from "./common";
import ssrReqWrapper from "@/lib/ssrReqWrapper";
import { personalizeBatchEventLogin, personalizeRegister } from "./personalize";
import { HandleJavaUsualFetch } from "../utils/fetch";
import { encryptVasl } from "../utils/encrypt";

const getTrackInfo = (params: string, obj?: any) => {
    //获取物流信息
    return HandlePhpFetch.get(TRACKINFO + params).then(
        (data) => {
            return data;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};

const isLogin = (headers?: any) => {
    let jwt;
    if (!headers) {
        jwt = getCookieItem(JWT_COOKIE_NAME);
    } else {
        jwt = null;
    }
    return !!jwt;
};

const facebookLogin = async (params: any, isSetCookie = true) => {
    try {
        const data = await HandlePhpFetch.post(FACEBOOKLOGIN, params);
        const time = new Date(new Date().getTime() + 8760 * 3600 * 1000);
        //不需要保持登陆，设置24小时。
        const time2 = new Date(new Date().getTime() + 12 * 3600 * 1000);
        data.jwt &&
            setCookieItem(JWT_COOKIE_NAME, data.jwt, {
                path: "/",
                expires: isSetCookie ? time : time2,
            });
        return data;
    } catch (e) {
        console.error("facebook-login", e);
    }
};

/**
 * 登录埋点
 * @param {string|undefined} email 用户邮箱 如果是Facebook登录不传
 * @param {any} error 错误
 * @param {number} loginStartAt 登录时间
 * @param {number} loginEndAt 登录结束时间
 * @param {string} loginMethod 登录方式 0账号密码登录 1Facebook登录
 */
const recordLogin = async (
    email: string | undefined,
    error: any,
    loginStartAt: number,
    loginEndAt: number,
    loginMethod: string,
) => {
    try {
        const loginId = await recordLoginBefore(
            email,
            loginStartAt,
            loginMethod,
        );
        if (error instanceof Error) {
            error = error.message;
        }
        return recordLoginAfter(error, loginId, loginEndAt);
    } catch (err) {
        console.error("recordLogin", err);
    }
};

const recordLoginBefore = async (
    email: string | undefined,
    loginStartAt: number,
    loginMethod: string,
) => {
    const params = {
        username: email,
        loginClickTime: loginStartAt,
        // 差个参数，这个接口应该只会在客户端调用
        siteId: window.getSiteId(),
        loginMethod,
    };

    return HandleJavaCommonFetch.post(LOGIN_MONITOR_BEFORE_API, params);
};

const recordLoginAfter = async (
    error: any,
    loginId: Response,
    loginEndAt: number,
) => {
    const params = {
        loginResType: error ? 2 : 1,
        remarks: error,
        id: loginId,
        loginRetTime: loginEndAt,
    };

    return HandleJavaCommonFetch.post(LOGIN_MONITOR_AFTER_API, params);
};

const login = async (
    params: Record<"email" | "password", any>,
    isSetCookie: boolean = true,
) => {
    const loginStartAt = Date.now();
    try {
        const data = await HandlePhpFetch.post(LOGIN_API, params);
        const time = new Date(new Date().getTime() + 8760 * 3600 * 1000);
        //不需要保持登陆，设置12小时。
        const time2 = new Date(new Date().getTime() + 12 * 3600 * 1000);

        setCookieItem(JWT_COOKIE_NAME, data.jwt, {
            path: "/",
            expires: isSetCookie ? time : time2,
        });
        recordLogin(params.email, undefined, loginStartAt, Date.now(), "0");
        return data;
    } catch (error: any) {
        recordLogin(params.email, error, loginStartAt, Date.now(), "0");
        throw new Error(error);
    }
};

const flowAfter = async (data: Record<string, any>) => {
    return await HandleJavaCommonFetch.post(
        "front/flow/source/event/data",
        data,
    );
};

const getDecode = async (params: Record<string, any>) => {
    //邀请 union/encode
    return HandlePhpFetch.get(DECODE, params);
};

const registerin = async (params: Record<string, any>) => {
    try {
        // 老用户带新用户，新用户注册时老用户会给一个邀请码，然后老用户可以获得优惠券之类的（有人证[cmx]的猜测）
        const invite_code = getCookieItem(INVITECODE_COOKIE_NAME);
        if (invite_code) {
            await getDecode({ invite_code }).then((data) => {
                params = { ...params, ...{ invite_id: data } };
            });
        }
        const resData = await HandlePhpFetch.post(REGISTER_API, params);
        const time = new Date(new Date().getTime() + 8760 * 3600 * 1000);
        resData.jwt &&
            setCookieItem(JWT_COOKIE_NAME, resData.jwt, {
                path: "/",
                expires: time,
            });
        // 未登录前用户行为记录
        personalizeRegister(params.email_address);
        personalizeBatchEventLogin(resData.customer_id);
        removeCookieItem(INVITECODE_COOKIE_NAME);
        return resData;
    } catch (error: any) {
        throw error;
    }
};

const sendPasswordForgotten = async (params: Record<string, any>) => {
    //忘记密码发送邮件
    return HandlePhpFetch.post(SENDFORGETPASSWORD, params);
};

// 该参数在源代码里存在，但是实际使用时调用处不需要
const getOrderCode = (
    params: string,
    cookie: string | null,
    //  hLang, hSiteId
) => {
    let sendParams = cookie ? { cookie } : {};
    //订单号查询订单ID
    return HandlePhpFetch.get(ORDERCODE + params, {
        params: sendParams,
        //   headers: { hLang, hSiteId }
    }).then(
        (data) => {
            return data;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};

const getOrderInfos = (params: any) => {
    //获取订单详情
    return HandlePhpFetch.get(REGISTER_API + ORDERS + params).then(
        (data) => {
            return data;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};

const unPhoto = (params: any) => {
    //取消照片
    return HandlePhpFetch.delete(REGISTER_API + PHOTO, params);
};

/**
 * 从后台获取用户上传图片列表。或者模特列表
 * @param customerEyePhotoType 1模特 2用户 3浏览者
 * @param photoType
 * @param customersPd
 * @returns {Promise<T>}
 */
const photoListJava = (
    customerEyePhotoType = 2,
    photoType = 1,
    customersPd = "63",
) => {
    return HandleJavaOpencvFetch.get(USER_PHOTO, {
        photoType,
        customersPd,
        customerEyePhotoType,
    })
        .then((data) => {
            return (data || []).map((it: any) => ({
                ...it,
                customerEyePhotoType,
                photo_img:
                    S3_URL() +
                    it.photo_path +
                    (it.photo_path.endsWith("/") ? "" : "/") +
                    "small-" +
                    it.photo_img,
            }));
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * 提交瞳孔信息
 * @param photoFullPath
 * @param cPhotoId
 * @param lEyeX
 * @param lEyeY
 * @param rEyeX
 * @param rEyeY
 * @returns {Promise<AxiosResponse<any>>}
 */
const postPhotoEyePositionJava = (
    photoFullPath: string,
    cPhotoId: string,
    lEyeX: string,
    lEyeY: string,
    rEyeX: string,
    rEyeY: string,
) => {
    return HandleJavaOpencvFetch.post(USER_PHOTO_SET_EYE_POSITION, {
        photoFullPath,
        cPhotoId,
        lEyeX,
        lEyeY,
        rEyeX,
        rEyeY,
    });
};
const getUserComment = (params: number) => {
    //获取根据订单来判断该订单下哪些是可评价，哪些是已评价的信息
    return HandlePhpFetch.get(POST_REVIEW + params).then(
        (data) => {
            return data;
        },
        (error) => {
            console.log(error);
            return Promise.reject(error);
        },
    );
};

//获取退款信息
const getRefundInfo = (orderId: number) => {
    return HandlePhpFetch.get(`${ORDERS}${orderId}/${ORDERS_REFUND}`);
};

//获取订单详情
const getSserviceShow = (params: string) => {
    return HandlePhpFetch.get(SERVICESHOW + params);
};

const postChangeReturn = (params: Record<string, any>) => {
    //退换货
    return HandlePhpFetch.post(CHANGE, params);
};

//退款
const refundOrder = (orderId: number, data: Record<string, any>) => {
    return HandlePhpFetch.post(`${ORDERS}${orderId}/${ORDERS_REFUND}`, data);
};

//获取自助服务信息
const getRefundServiceInfo = (data: Record<string, any>) => {
    return HandlePhpFetch.get(
        `${ORDERS_SERVICE}?orders_id=${data.orders_id}&service_type=${data.service_type}`,
    );
};

//提交自助服务信息
const postRefundServiceInfo = (params = {}) => {
    return HandlePhpFetch.post(ORDERS_SERVICE, params);
};

//去掉某个产品的自助服务信息
const delRefundServiceInfo = (productId: number) => {
    return HandlePhpFetch.delete(`${ORDERS_SERVICE_DELETE}${productId}`);
};

//新增获取用户专属优惠券接口
const getUserPromotionCoupon = (params = {}) => {
    return HandlePhpFetch.post(PROMOTION_COUPON, params);
};

function mapNeedData(data: any[]) {
    if (data?.map) {
        return data.map((item: any) => {
            const {
                products_id,
                products_date_added_stortime,
                master_categories_id,
                products_color_relation,
                products_model,
                products_name_attribute,
                products_sex,
                products_reviews,
                products_avg_rating,
                products_months_ordered,
                dimensions_lens,
                dimensions_bridge,
                dimensions_height,
                dimensions_temple,
                dimensions_total_width,
                dimensions_diagonal,
                products_weight,
                products_metal,
            } = item;
            return {
                products_id,
                products_date_added_stortime,
                master_categories_id,
                products_color_relation,
                products_model,
                products_name_attribute,
                products_sex,
                products_reviews,
                products_avg_rating,
                products_months_ordered,
                dimensions_lens,
                dimensions_bridge,
                dimensions_height,
                dimensions_temple,
                dimensions_total_width,
                dimensions_diagonal,
                products_weight,
                products_metal,
            };
        });
    }
    return data;
}
//组装自己需要数据
function assembleData(data: any) {
    let hotData = mapNeedData(data.hot);
    return {
        hot: hotData,
        news: mapNeedData(data.news),
    };
}

interface IIndexProducts {
    limit: number;
    type: boolean;
}

export const indexProducts = (params: IIndexProducts) => {
    //首页产品

    let prom = HandlePhpFetch.get(INDEX_PRODUCTS, params).then(
        (data: any) => {
            return assembleData(data);
        },
        (error) => {
            console.error(error);
            return Promise.reject(error);
        },
    );
    if (isServer) {
        prom = performanceRecordWrapper({
            requestUrl: INDEX_PRODUCTS,
            prom,
            mountType: 8,
        });
    }
    return ssrReqWrapper(INDEX_PRODUCTS, prom);
};
const flowadd = (data: any) => {
    return HandleJavaCommonFetch.post(
        "/front/flow/source/page/event/data",
        data,
    );
};

const changeSubscription = (params: Record<string, any>) => {
    //修改订阅
    return HandlePhpFetch.post(REGISTER_API + SUBSCRIPTION, params);
};

const unSubscribe = (params: any) => {
    //取消订阅
    return HandlePhpFetch.get(REGISTER_API + UNSUBSCRIBE, { params });
};

//判断新老用户
//指定显示用户类型 1、全部用户 2已登录已下单用户 3、未登录用户&已登录未下单用户
//4、未登录用户 5、已登录未下单
function initAd(data: any) {
    const checkedLogin = isLogin();
    //是否登陆
    const result: any[] = [];
    data.forEach((element: any) => {
        if (!element.area_id) {
            element = {};
        } else {
            const list = element.list || [];
            //确定用户类型
            // 已登录 + 已下单 老用户 2
            // 未登录 4
            // 已登录 未下单 5
            // 优先级 4 5 2 > 4U5 > 1
            // 4U5 === 3
            let user_type = element.is_old_customer ? 2 : checkedLogin ? 5 : 4;
            let ad_match_item = "";
            let three_item = "";
            let default_ad_item = {};
            //匹配用户
            list.forEach((item: any) => {
                if (item.show_user_type === user_type) {
                    ad_match_item = item;
                }
                if (
                    item.show_user_type === 3 &&
                    (user_type === 4 || user_type === 5)
                ) {
                    three_item = item;
                }
                if (item.show_user_type === 1) {
                    default_ad_item = item;
                }
            });

            element = ad_match_item
                ? ad_match_item
                : three_item
                  ? three_item
                  : default_ad_item;
        }
        result.push(element);
    });
    return result;
}

export const getAd = (params: string) => {
    //广告
    // const sendParams = cookie ? { cookie } : {};
    const url = AD + "/" + params;
    let prom = HandlePhpFetch.get(url)
        .then(
            (data) => {
                return initAd(data);
            },
            (error) => {
                console.log(error, "inside error");
                return Promise.reject(error);
            },
        )
        .catch((e) => {
            console.log(e);
            return Promise.reject(e);
        });
    return prom;
};

export const traceKey = "acb9cbe31b0d185898972f214208382df434";

export const tryGo = () => {
    const params = {
        [traceKey]: encryptVasl(),
    };

    return HandleJavaUsualFetch.post("/traceId", params);
};

export const getCountries = () => {
    //获取国家
    return HandlePhpFetch.get(COUNTRIES).then(
        (data) => {
            data.list = data.list.map((it: any) => {
                return {
                    ...it,
                    shipping: it.shipping_new,
                };
            });
            return data;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};

export const telephone = (data: Record<string, any>) => {
    return HandleJavaUsualFetch.post("/customers/telephone/valid", data);
};

export {
    isLogin,
    facebookLogin,
    recordLogin,
    getTrackInfo,
    recordLoginBefore,
    recordLoginAfter,
    getRefundInfo,
    login,
    flowAfter,
    getDecode,
    getUserComment,
    postPhotoEyePositionJava,
    registerin,
    sendPasswordForgotten,
    getOrderCode,
    getOrderInfos,
    unPhoto,
    photoListJava,
    getSserviceShow,
    postChangeReturn,
    refundOrder,
    getRefundServiceInfo,
    postRefundServiceInfo,
    delRefundServiceInfo,
    getUserPromotionCoupon,
    flowadd,
    changeSubscription,
    unSubscribe,
};
